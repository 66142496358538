// eslint-disable-next-line no-undef
window._config = {
  baseUrl: 'https://stage.login.bandwidth.com',
  dashboard: 'https://eng.dashboard.bandwidth.com',
  irisApi: 'https://eng.dashboard.bandwidth.com/api/v2',
  oneIdManagementApi: 'https://stage.lab.management.id.bandwidth.com',
  oidc: {
    issuer: 'https://stage.login.bandwidth.com/oauth2/ausqxr6vbsMh6tmN30h7',
    clientId: '0oavvcy4dc7RT1Pa70h7',
    redirectUri: 'https://stage.passport.bandwidth.com/login',
    postLogoutRedirectUri: 'https://stage.passport.bandwidth.com/logout',
    scopes: ['openid', 'oneid', 'email', 'profile'],
    pkce: true,
    storageManager: {
      token: {
        storageTypes: ['sessionStorage'],
      },
    },
  },
  cookies: {
    secure: true,
  },
  datadog: {
    env: 'stage',
    applicationId: 'a8c3461d-e18a-4539-9b0f-4f4833918058',
    clientToken: 'pub81d618dcf76d9080292c09d3a983fe21',
  },
};
